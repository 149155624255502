h1, h2, h3 {
  font: {
    weight: normal;
  }
  color: $main-heading-color;
  margin: {
    top: 1.5em;
    bottom: .5em;
  }
}

h1 {
  font-size: 1.8rem;
  line-height: 1.2;

  @media (max-width: 800px) {
    font-size: 1.6rem;
  }

  @media all and (max-width: 680px) {
    font-size: 1.5rem
  }
}

h2 {
  font: {
    size: 1.5rem;
  }
  line-height: 1.3;

  @media (max-width: 800px) {
    font-size: 1.2rem;
  }
}

h3 {
  letter-spacing: .5px;
  font-size: 1.1rem;
  line-height: 1.5;
  @media (max-width: 740px) {
    font-size: 1.1rem;
  }
}
