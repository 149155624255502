@import 'var';
@import "mixins";
@import 'headings';
@import 'buttons';
@import "header";
@import "portfolio";
@import "portfolio-item";
@import "contact-info";
@import "resume";
@import "page-transitions";


html {
  font: {
    size: 20px;
    family: 'Segoe UI Historic', //Windows 10
    'Segoe UI', //Windows Vista
    'Helvetica Neue', //Mac OS X 10.7 Lion
    sans-serif;
  }
  line-height: 2;
  background-color: $site-bg;
  color: $main-text-color;


  @media (max-width: 600px) {
    font-size: 16px;
  }
}

body {
  margin: 2rem;

  @media (max-width: 680px) {
    margin: 1.5rem;
  }

  @media (max-width: 450px) {
    margin: 1rem;
  }
}

ul {
  padding: 0;
  list-style: none;

  @media all and (max-width: 680px) {
    margin-left: .75rem;
  }

  &.right-aligned {
    direction: rtl;
    margin-left: 0;
    margin-right: .75rem;
  }
}

figure {
  margin: 0;
}

.non-jumpy-img {
  position: relative;
  height: 0;
  //padding-bottom: 25.2312323%; use style attribute
  font-size: 0;
  line-height: 0;
  display: block;
  width: 100%;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

p {
  margin-top: .5em;
}


img {
  max-width: 100%;
}

a {
  color: $btn-color;
}
