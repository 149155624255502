.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}



.next-exit-active {
  animation: leaveToLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

.next-enter-active {
  animation: enterFromRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.prev-exit-active {
  animation: leaveToRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.prev-enter-active {
  animation: enterFromLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

@keyframes leaveToLeft {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes enterFromLeft {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes leaveToRight {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes enterFromRight {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
