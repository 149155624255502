$site-bg: #fdfdfd;
$main-text-color: #1c1212;

$status-indicator-color: #35901a;

$btn-color: #4b667c;
$btn-hover-color: #3D5D77;
$btn-text-color: #FFF;
$btn-focus-color: #2e4455;

$main-heading-color: #1c1212;

$headings-color: #3d2c0d;
$heading-lines-color: #827458;

$footer-text-color: #949494;
$footer-link-color: #2b2b2b;
$footer-line-color: #eaeaea;
