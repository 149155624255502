.resume {
  @include content-width-limit;
  max-width: 800px;

  > h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    list-style: disc;
  }

  strong {
    font-weight: 500;
    text-decoration: underline;
  }
}
