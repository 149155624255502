.btn:not(.alt), button:not(.alt) {
  background-color: $btn-color;
  border-radius: 40px;
  border: none;
  appearance: none;
  cursor: pointer;
  line-height: 1;
  color: $btn-text-color;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  padding: .35rem;
  display: inline-block;
  vertical-align: middle;

  &:hover {
    background-color: $btn-hover-color;

    span:not(.sr-only) {
      text-decoration: none;
      background-color: $btn-hover-color;
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent .5rem,
        $btn-focus-color .5rem,
        $btn-focus-color 1rem /* determines size */
    );
    background-size: 400% 400%;
    animation: move-bg 7s linear infinite;
  }

  span:not(.sr-only) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $btn-color;
    border-radius: 40px;
    padding: .2rem .65rem;
    font-size: 1rem;
  }

  img, svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: .6rem;
  }

  svg path {
    fill: #FFFFFF;
  }

  &.round {
    width: 3.25rem;
    height: 3.25rem;

    img, svg {
      margin-right: 0;
      width: 1.75rem;
      height: 1.75rem;
    }

    span:not(.sr-only) {
      padding: 0.45rem;
    }

    @media (max-width: 430px) {
      width: 2.5rem;
      height: 2.5rem;

      img, svg {
        margin-right: 0;
        width: 1.25rem;
        height: 1.25rem;
      }

      span:not(.sr-only) {
        padding: 0.35rem;
      }
    }
  }
}


@keyframes move-bg {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 100%;
  }
}
