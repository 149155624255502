.contact-info {
  @include content-width-limit;
  display: flex;
  justify-content: space-between;

  @media (max-width: 420px) {
    display: block;
    width: 230px;
  }

  .contact-points {
    margin: 0;

    .contact-point {
      margin-bottom: .7rem;

      .btn {
        margin-right: .3rem;
        white-space: nowrap;


        @media (max-width: 420px) {
          display: block;
          width: 100%;

          span {
            justify-content: left;
          }
        }
      }
    }
  }

  .qr-code--v-card {
    width: 300px;
    text-align: center;

    .scan {
      line-height: 1.25;
    }

    .btn {
      margin-bottom: 1.5rem;
      white-space: nowrap;

      .extra-wording {
        padding: 0;

        @media (max-width: 470px) {
          display: none;
        }
      }


      @media (max-width: 420px) {
        display: block;
        margin: {
          top: 2rem;
          bottom: 0;
        }

        span {
          justify-content: left;
        }
      }

    }


    @media (max-width: 600px) {
      width: 200px;
    }

    @media (max-width: 470px) {
      width: 150px;
    }
    
    @media (max-width: 420px) {
      text-align: center;
      width: auto;

      .btn .extra-wording {
        display: inline
      }
    }


    @media (max-width: 420px) {
      .scan {
        margin-top: 2rem;
      }
    }
  }


}
