.portfolio-item {
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    @include content-width-limit;

    .name--desc--url {
      text-align: center;
      margin: {
        left: .2rem;
        right: .2rem;
      }

      @media (max-width: 350px) {
        margin: {
          left: 0;
          right: 0;
        }
      }
    }

    h2 {
      margin: {
        top: 0;
        bottom: .3rem;
      }
    }

    .desc, .url {
      line-height: 1.5;
    }

    @media (max-width: 800px) {
      margin-bottom: 1.4rem;
    }

    @media (max-width: 349px) {
      margin-bottom: .5rem;
      .btn {
        display: none;
      }
    }
  }

  .features--tech {
    margin-bottom: 2rem;
    @include content-width-limit;

    h3 {
      margin-top: 0;
    }
  }

  .features {
    display: flex;
    margin: {
      left: -.75rem;
      right: -.75rem;
      bottom: 1rem;
    }

    ul {
      list-style: disc;
      margin: {
        top: 0;
        bottom: 0;
      }

      li {
        line-height: 1.5;
        margin-bottom: .5rem;
      }

      @media (max-width: 640px) {
        width: auto;
        margin-left: .25rem;

        padding: {
          left: .75rem;
          right: .75rem;
        }
      }
    }

    @media (max-width: 640px) {
      display: block;

      margin: {
        left: 0;
        right: 0;
      }
    }
  }

  .tech {
    .col {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      line-height: 2.2rem;
      margin: {
        left: -.5rem;
        right: -.5mre;
      }
      justify-content: center;

      .feature {
        display: flex;
        align-items: center;
        margin: {
          right: .5rem;
          left: .5rem;
        }
      }

      img {
        $icon-size: 1.5rem;
        width: $icon-size;
        height: $icon-size;
        margin-right: .25rem;
      }
    }
  }

  figure {
    background: #ececec;
    border-radius: 10px;
    width: fit-content;
    margin: {
      left: auto;
      right: auto;
      bottom: 3rem;
    }
    line-height: 0;
    font-size: 0;
    overflow: hidden;
    box-shadow: 0 0 20px #989898;

    @media (max-width: 375px) {
      margin: {
        left: -.75rem;
        right: -.75rem;
      }
      border-radius: 0;
    }

    .title-bar {
      display: flex;
      align-items: center;
      padding: {
        left: 10px;
        right: 10px;
      }
      font-size: 1rem;
      line-height: 2;
    }

    .title {
      flex-grow: 1;
    }

    button {
      background: #adadad;
      margin-left: 10px;
    }
  }
}
