.portfolio-wrapper {
  @include content-width-limit;
  max-width: 800px;

  footer {
    position: relative;
    padding-top: 30px;
    text-align: center;
    height: 150px;
    overflow: hidden;

    a {
      display: inline-block;
    }

    img {
      position: relative;
      display: block;
      margin: auto;
    }

    .wp-ani-logo-outside {
      width: 150px;
      animation: {
        name: rotation;
        duration: 30s;
        iteration-count: infinite;
        timing-function: linear;
      };
    }

    .wp-ani-logo-center {
      width: 90px;
      top: -105px;
    }
  }
}

.portfolio {
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  margin: {
    top: 1rem;
    bottom: 2rem;
    left: -1.5rem;
    right: -1.5rem;
  }
  align-items: center;

  @media all and (max-width: 800px) {
    margin: {
      left: -1.25rem;
      right: -1.25rem;
    }
  }
  @media all and (max-width: 500px) {
    margin: {
      left: -1rem;
      right: -1rem;
    }
  }

  @media all and (max-width: 400px) {
    margin: {
      left: -.75rem;
      right: -.75rem;
    }
  }

  li {
    box-sizing: border-box;
    width: 33.3%;
    text-align: center;
    padding: 1.5rem;

    @media all and (max-width: 800px) {
      padding: 1.25rem;
    }

    @media all and (max-width: 600px) {
      width: 50%;
    }

    @media all and (max-width: 500px) {
      padding: 1rem;
    }

    @media all and (max-width: 400px) {
      padding: .75rem;
    }
  }

  figure {
    //box-shadow: 0 0 10px #989898;
    cursor: pointer;
    transition: border-color .5s, box-shadow .4s;
    font-size: 0;
    overflow: hidden;
    width: 100%;

    /*&:hover {
      box-shadow: 0 0 5px #989898;
    }*/

    img {
      width: 100%;
      height: auto;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}