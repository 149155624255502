header.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: {
    left: auto;
    right: auto;
    bottom: 2rem;
  };

  @media all and (max-width: 680px) {
    margin-bottom: 1.25rem;
  }

  @media (max-width: 500px) {
    display: block;
    text-align: center;
  }

  .logo-enter {
    transition: all .4s;
    transform: translateX(-100%);
    opacity: 0;

    &.logo-enter-active {
      transform: translateX(0);
      opacity: 1;
    }
  }

  h1 {
    color: $main-heading-color;
    margin: {
      top: 0;
      bottom: 0;
    }
    font-size: 1.25rem;

    a {
      color: $main-heading-color;
      text-decoration: none;
    }
  }

  nav {
    margin-right: -.7rem;

    @media (max-width: 500px) {
      margin: {
        right: 0;
        left: 0;
      }
    }

    a {
      font-size: 1rem;
      color: $main-heading-color;
      margin: {
        right: .7rem;
        left: .7rem;
      }
      text-decoration: none;
      display: inline-block;

      &.active {
        border-bottom: 2px solid $main-heading-color;
      }


      @media (max-width: 370px) {
        margin: {
          right: .4rem;
          left: .4rem;
        }
      }
    }
  }
}
